/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import Button from "../components/atoms/Button/Button"

import * as styles from "./styleguide.module.css"

const StyleGuide = () => {
    return (
        <Layout>
            <Seo title="Style Guide" />
            <Container>
                <PageTitle title="Style Guide" />

                <div className={styles.styleExample}>
                    <h1>Heading 1</h1>
                    <code>&lt;h1&gt;Heading 1&lt;/h1&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h2>Heading 2</h2>
                    <code>&lt;h2&gt;Heading 2&lt;/h2&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h3>Heading 3</h3>
                    <code>&lt;h3&gt;Heading 3&lt;/h3&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h4>Heading 4</h4>
                    <code>&lt;h4&gt;Heading 4&lt;/h4&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h5>Heading 5</h5>
                    <code>&lt;h5&gt;Heading 5&lt;/h5&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <h6>Heading 6</h6>
                    <code>&lt;h6&gt;Heading 6&lt;/h6&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p class="subtitle">Subtitle Text</p>
                    <code>&lt;p class="subtitle"&gt;Subtitle Text&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p class="subtitle small">Small Subtitle Text</p>
                    <code>&lt;p class="subtitle small"&gt;Small Subtitle Text&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p>Standard Body Copy</p>
                    <code>&lt;p&gt;Standard body copy&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p class="lowFont">Small Body Copy</p>
                    <code>&lt;p class="lowFont"&gt;Small body copy&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <p class="lowerFont">Smaller Body Copy</p>
                    <code>&lt;p class="lowerFont"&gt;Smaller body copy&lt;/p&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <label>Form Label</label>
                    <code>&lt;label&gt;Form label&lt;/label&gt;</code>
                </div>
                <div className={styles.styleExample}>
                    <span class="overline">Overline</span>
                    <code>&lt;span class="overline"&gt;Overline&lt;/span&gt;</code>
                </div>
                <div className={styles.buttonGroup}>
                    <div className={styles.styleExample}>
                        <Button type="a" level="primary" href="#">Primary Button</Button>
                        {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                    </div>
                    <div className={`${styles.styleExample} ${styles.color}`}>
                        <Button type="a" level="primary" alternate={true} href="#">Alternate Primary Button</Button>
                        {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                    </div>
                    <div className={styles.styleExample}>
                        <Button type="a" level="primary" href="#" disabled={true}>Disabled Primary Button</Button>
                        {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                    </div>
                </div>
                <div className={styles.buttonGroup}>
                    <div className={styles.styleExample}>
                        <Button type="a" level="secondary" href="#">Secondary Button</Button>
                        {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                    </div>
                    <div className={`${styles.styleExample} ${styles.color}`}>
                        <Button type="a" level="secondary" alternate={true} href="#">Alternate Secondary Button</Button>
                        {/* <code>&lt;a href="#" class="button button--primary"&gt;Primary button&lt;/a&gt;</code> */}
                    </div>
                    <div className={styles.styleExample}>
                        <Button type="a" level="secondary" href="#" disabled={true}>Disabled Secondary Button</Button>
                        {/* <code>&lt;a href="#" class="button button--primary disabled" disabled&gt;Disabled primary button&lt;/a&gt;</code> */}
                    </div>
                </div>
                <div className={styles.colorGroup}>
                    <p class="subtitle3">Primary Colours</p>
                    <div className={styles.colorRow}>
                        <div className={`${styles.colorBox} bg-yellow`}>
                            {/* --color-yellow in Globals.css */}
                            <p class="body2 font-black">Yellow</p>
                            <p class="body2 font-black">--color-yellow</p>
                            <p class="body2 font-black">#FCE300</p>
                        </div>
                        <div className={`${styles.colorBox} bg-pink`}>
                            {/* --color-pink in Globals.css */}
                            <p class="body2 font-black">Pink</p>
                            <p class="body2 font-black">--color-pink</p>
                            <p class="body2 font-black">#F8DCD7</p>
                        </div>
                        <div className={`${styles.colorBox} bg-blue`}>
                            {/* --color-blue in Globals.css */}
                            <p class="body2 font-black">Blue</p>
                            <p class="body2 font-black">--color-blue</p>
                            <p class="body2 font-black">#95D3E0</p>
                        </div>
                        <div className={`${styles.colorBox} bg-green`}>
                            {/* --color-green in Globals.css */}
                            <p class="body2 font-black">Green</p>
                            <p class="body2 font-black">--color-green</p>
                            <p class="body2 font-black">#B4D376</p>
                        </div>
                        <div className={`${styles.colorBox} bg-purple`}>
                            {/* --color-purple in Globals.css */}
                            <p class="body2 font-black">Purple</p>
                            <p class="body2 font-black">--color-purple</p>
                            <p class="body2 font-black">#BEA0D0</p>
                        </div>
                    </div>
                    {/* <div className={styles.colorRow}>
                        <div className={`${styles.colorBox} bg-black`}>
                            {/* --standard-black in Globals.css /}
                            <p class="body2 font-white">Black</p>
                            <p class="body2 font-white">#000000</p>
                        </div>
                        <div className={`${styles.colorBox} bg-charcoal`}>
                            {/* --filter-blue in Globals.css /}
                            <p class="body2 font-white">Charcoal</p>
                            <p class="body2 font-white">#273139</p>
                        </div>
                        <div className={`${styles.colorBox} bg-grey`}>
                            {/* --filter-blue in Globals.css /}
                            <p class="body2 font-black">Dark Grey</p>
                            <p class="body2 font-black">#A8B2BA</p>
                        </div>
                        <div className={`${styles.colorBox} bg-grey-light`}>
                            {/* --filter-blue in Globals.css /}
                            <p class="body2 font-black">Light Grey</p>
                            <p class="body2 font-black">#EEF1F3</p>
                        </div>
                    </div> */}
                </div>

                <div className={styles.fontWeights}>
                    <p class="subtitle3">Font weights</p>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight100}>Font Weight - Thin (Hairline) - 100</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight200}>Font Weight - Extra Light (Ultra Light) - 200</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight300}>Font Weight - Light - 300</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight400}>Font Weight - Normal (Regular) - 400</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight500}>Font Weight - Medium - 500</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight600}>Font Weight - Semi Bold (Demi Bold) - 600</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight700}>Font Weight - Bold - 700</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight800}>Font Weight - Extra Bold (Ultra Bold) - 800</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight900}>Font Weight - Black (Heavy) - 900</span>
                    </div>
                    <div className={styles.styleExample}>
                        <span className={styles.fontWeight950}>Font Weight - Extra Black (Ultra Black) - 950</span>
                    </div>
                </div>
            </Container>
        </Layout>
    )
  }
  
  export default StyleGuide
  