import React, { useContext, useMemo, useState } from 'react'
import parse from 'html-react-parser';
import { renderToString } from 'react-dom/server';
import AuthContext from '../../../context/AuthProvider';
import Button from '../../atoms/Button/Button';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import Container from '../../atoms/Container/Container';
import EventCard from '../../molecules/EventCard/EventCard';
import Icon from '../../atoms/Icon/Icon';
import CustomSlider from '../CustomSlider/CustomSlider';
import Dialog from '../../atoms/Dialog/Dialog';
import Radio from '../../atoms/Radio/Radio';
import OrttoEmailTemplate from '../OrttoEmailTemplate/OrttoEmailTemplate';
import { paymentIntent, upsertCustomer } from '../../../helpers/stripe';
import StripePayment from '../../molecules/StripePayment/StripePayment';
import { wpApiNew } from "../../../helpers/wordpress";
import { getPerson, updatePerson, sendEmail, getTags } from '../../../helpers/ortto';
import { error, success } from '../../../helpers/toast';

import * as styles from './Club.module.css';

const Club = ({club, events}) => {

    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userEmail = (auth && auth.state.email) || false;
    const orttoKey = `club-${club.slug}`;
    const [selectedMembership, setSelectedMembership] = useState(null);
    const [orttoMemberLevel, setMemberLevel] = useState(null);
    const [membershipCost, setMembershipCost] = useState(null);
    const [stripeClientSecret, setClientSecret] = useState(null);
    const [transactionSuccess, setTransactionSuccess] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [working, setWorking] = useState(false);
    const [status, setStatus] = useState(null);
    const [person, setPerson] = useState(null);
    const [returnUrl, setReturnUrl] = useState(null);

    useMemo(async () => {
        // If user is logged in, check if they are already a member of the club
        if (isLoggedIn && status === null) {
            const result = await getPerson(["tags"], userEmail);
            if (result.status === 200 && "contacts" in result.response && result.response.contacts !== null && result.response.contacts.length > 0) {
                // Successfully found
                setPerson(result.response.contacts[0]);
                const rUrl = `${(typeof window !== 'undefined' ? window.location.href : '/')}?`
                setReturnUrl(rUrl);
                if (result.response.contacts[0].fields && result.response.contacts[0].fields.tags && result.response.contacts[0].fields.tags.indexOf(orttoKey) > -1) {
                    setStatus(true);
                } else {
                    setStatus(false);
                }
            } else {
                setStatus(false);
            }
        }
    }, [isLoggedIn, userEmail, status, orttoKey]);

    // const devEvents = new Array(3).fill(...events);

    const getExpiryDate = () => {
        // console.log(club);
        if (club.applicationSettings.campaign_start_date === '') return null;
        const expireDate = new Date(club.applicationSettings.campaign_start_date);
        // console.log(expireDate);
        expireDate.setDate(expireDate.getDate() + Number(club.applicationSettings.accepting_signatures_period));
        const options = {
            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', 
            hour: 'numeric', minute: 'numeric',
            hour12: true,
            timeZone: 'Australia/Sydney',
        };
        // console.log(expireDate);
        return new Intl.DateTimeFormat('default', options).format(expireDate);
    }

    const joinClub = async () => {
        /***
         * Hardcoded override for Jewish club as a security measure
         */
        if (club.slug === 'australasian-union-of-jewish-students-university-of-sydney') {
            window.open('https://forms.aujs.com.au/aujsltd/form/MembershipForm2024/formperma/yb9NkQrIE0s0qtdbu01uwxKKNxGDTQ5AW-wZLJQ4Mtg', '_blank');
            return true;
        }
        /***
         * End security measure
         */
        if ( (club.fees && club.fees.length > 1) || (club.fees && club.fees.length === 1 && !club.disable_free_membership) ) {
            // Multiple options exist so trigger option to choose
            setShowDialog(true);
        } else if (club.fees && club.fees.length === 1 && club.disable_free_membership) {
            // Single option exists, determine if it needs to be paid for
            const fee = club.fees[0];
            const value = Number(fee.value);
            const cost = (value && value > 0) ? value : 'free';

            if (cost === 'free') {
                setMemberLevel('Standard')
                registerUser('Standard');
            } else {
                // Value to be paid - process through stripe
                setMemberLevel(fee.label);
                setMembershipCost(cost);
                setShowDialog(true);
            }
        } else {
            // No fees defined - ignore disabled option as force free
            setMemberLevel('Standard')
            registerUser('Standard');
        }
    }

    const handleMemberSelect = (_value) => {
        setSelectedMembership(_value);
    }

    const assignMemberSelect = () => {
        if (selectedMembership === 'free') {
            setShowDialog(false);
            setMemberLevel('Standard');
            registerUser('Standard');
        } else {
            const fee = JSON.parse(selectedMembership);
            const value = Number(fee.value);
            const cost = (value && value > 0) ? value : 'free';

            if (cost === 'free') {
                setShowDialog(false);
                setMemberLevel('Standard');
                registerUser('Standard');
            } else {
                // Value to be paid - process through stripe
                setMemberLevel(fee.label);
                setMembershipCost(cost);
                const customerMetaData = {
                    'USU Member Number': auth.state.usu.MemberNumber,
                };
                const paymentMetaData = {
                    'USU Member Number': auth.state.usu.MemberNumber,
                    'USU Club': club.title,
                    'USU Club ID': club.databaseId,
                    'USU Club Membership Level': fee.label
                };
                upsertCustomer(auth.state.email, `${auth.state.usu.FirstName} ${auth.state.usu.LastName}`, customerMetaData, club.stripeConnectId || process.env.GATSBY_STRIPE_DEFAULT_CONNECT).then(customer => {
                    // console.log(customer);
                    if (String(customer.status).startsWith("2")) {
                        paymentIntent(cost, club.stripeConnectId || process.env.GATSBY_STRIPE_DEFAULT_CONNECT, customer.response.id, paymentMetaData).then(response => {
                            if (String(response.status).startsWith('2') && 'client_secret' in response.response) {
                                setClientSecret(response.response.client_secret);
                            } else {
                                // TODO: Handle stripe errors
                            }
                        });
                    }
                })
            }
        }

        return true;
    }

    const handlePayment = (object) => {
        if (object && object.status === 'succeeded') {
            setClientSecret(null);
            setTransactionSuccess(true);
            registerUser(orttoMemberLevel);
        } else {
            // This should never happen because the check for the error is done in the StripePayment component.
            console.log(object, object.error);
        }
    }

    const registerUser = async (memberLevel) => {
        // console.log("Would register for ", memberLevel);
        if (status === false) {
            setWorking(true);
            const clubKey = orttoKey;
            const clubLevel = `clubLevel-${club.slug}-${memberLevel}`;
            const clubJoinDate = `clubJoined-${club.slug}-${Date.now()}`
            const userKey = userEmail;

            const personData = [
                {
                email: userKey,
                tags: [clubKey, clubLevel, clubJoinDate],
                }
            ]
            const updateResponse = await updatePerson(personData);
            if (updateResponse.status === 200 && "people" in updateResponse.response) {
                if (club.status === 'signatures') {
                    const tags = await getTags(orttoKey);
                    if (String(tags.status).startsWith('2') && tags.response.length > 0) {  
                        const joinedTag = tags.response.filter((tag) => {
                            return tag.name === `clubLevel-${club.slug}-${memberLevel}`
                        })
                        if (joinedTag && joinedTag.length > 0 && joinedTag[0].count >= Number(club.applicationSettings.minimum_amount_of_signatures_required)) { // tags.response[0].count
                            wpApiNew('setClubToCompliant', {clubId: club.databaseId});
                        }
                    }
                }
                // Successfully added
                const clubData = await wpApiNew('getClubPublicData', {
                    clubId: club.databaseId,
                    fieldSet: 3
                });
                if (String(clubData.status).startsWith("2") && clubData.response) {
                    const welcomeEmail = clubData.response.acf.club_preferences?.welcome;
                    if (welcomeEmail && welcomeEmail.enabled) {
                        const _clubData = clubData.response;
                        const emailBody = renderToString(<OrttoEmailTemplate logo={_clubData.acf.logo} clubName={_clubData.title.rendered} content={welcomeEmail.content} />);
                        // console.log("Email Body:", emailBody);

                        const emailOptions = {
                            html_body: emailBody,
                            subject: welcomeEmail.subject,
                            email_name: `${_clubData.title.rendered} club automation - Welcome`,
                            from_name: _clubData.acf.club_preferences.sender_name,
                            reply_to: _clubData.acf.club_preferences.sender_email
                        };

                        const recipients = [{
                            email: userEmail, // "ash@matterdesign.com.au"
                            first: person.fields['str::first'] || '',
                            last: person.fields['str::last'] || ''
                        }]
                        
                        sendEmail(emailOptions, recipients).then(() => {
                            // Regardless of the outcome here, we need to show the user they registered successfully
                            setWorking(false);
                            setStatus(true);
                            setShowDialog(false);
                            success('Joined')
                        });
                    } else {
                        // Club has not enabled the welcome email to be sent on registration
                        setWorking(false);
                        setStatus(true);
                        setShowDialog(false);
                        success('Joined')
                    }
                } else {
                    // Error fetching club data to potentially send welcome email if enabled, show the registration was successful to the user.
                    setWorking(false);
                    setStatus(true);
                    setShowDialog(false);
                    success('Joined')
                }
            } else {
                error("Fail to join club")
                setWorking(false);
                // console.log(updateResponse);
                // Error occurred
                // TODO: Show error somewhere
            }
        }
    }

    return (
        <div className={ styles.Club }>
        {/* <div className='banner' style={{backgroundImage: `url(${club.featuredImage})`, minHeight: '500px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <Container>
                <h1>{club.title}</h1>
            </Container>
        </div> */}

        <Container>
            <div className={styles.headerClubContainer}>
                <Breadcrumbs crumbs={[{'label': 'Clubs', 'link': '/clubs/'}, {'label': club.title}]} />
                <h1 className={styles.title}>{club.title}</h1>
            </div>
            <div className={styles.clubGrid}>
                
                <div className={styles.mainContainer}>
                    {club.content && parse(club.content)}
                    {club.whyJoin && parse(club.whyJoin)}
                    {club.fomo && parse(club.fomo)}
                    {club.meetups && parse(club.meetups)}

                    {events && events.length !== 0 && (
                        <div className={styles.eventsContainer}>
                            
                            <div className={styles.hideOnMobile}>
                                <CustomSlider title={'Upcoming Events'} slidesToShow={2}>
                                    {events.map((event, eventIndex) => {
                                        return <EventCard aspectRatio1x1={true} customStyle={true} eventObj={event} key={eventIndex} />
                                    })}
                                </CustomSlider>
                            </div>
                            
                            <div className={styles.mobileEventsContainer}>
                                <h3>Upcoming Events</h3>
                                <div className={styles.overflowContainer}>
                                    
                                    {events.map((event, eventIndex) => {
                                        return <EventCard aspectRatio1x1={true} eventObj={event} key={eventIndex} />
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.sideContainer}>
                    {club.logo && (
                        <div className={styles.logoContainer}>
                            <img src={club.logo} alt={club.title} />
                        </div>
                    )}
                    
                    {['signatures', 'publish'].indexOf(club.status) > -1 && (
                        <div className={styles.clubMetaContainer}>
                            {isLoggedIn ? (
                                <Button className={styles.ctaButton} level="primary" type="button" disabled={status !== false || working} onClick={() => joinClub()}>{
                                    club.status === 'signatures' ? (
                                        status === true ? 'Interested' : (working ? 'Registering...' : 'Register interest')
                                    ) : (
                                        status === true ? 'Joined' : (working ? 'Joining...' : 'Join Now')
                                    )
                                }</Button>
                            ) : (
                                <Button className={styles.ctaButton} level="primary" type="a" href="/login/">Log in</Button>
                            )}

                            {club.status === 'signatures' && (
                                <div className={styles.expiryContainer}>
                                    <h6>Interest registration expires:</h6>
                                    <div>{getExpiryDate()}</div>
                                </div>
                            )}
                        
                            {club.status === 'publish' && (
                                <>
                                    <div className={styles.membershipFeesContainer}>
                                        {(
                                            (Array.isArray(club.fees) && club.fees.length > 1) ||
                                            (Array.isArray(club.fees) && club.fees.length === 1 && !club.disableFreeMemberships)
                                        ) ? (
                                            <>
                                                <h6>Membership tiers:</h6>
                                                {!club.disableFreeMemberships && (
                                                    <div className={styles.fee}>
                                                        <span>
                                                            Standard: Free
                                                        </span>
                                                    </div>
                                                )}
                                                {club.fees.map((fee, feeIndex) => (
                                                    <div key={feeIndex} className={styles.fee}>
                                                        <span>
                                                            {fee.label}: {fee.value ? `$${fee.value}` : 'Free'}
                                                        </span>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {(Array.isArray(club.fees) && club.fees.length === 1 && club.disableFreeMemberships) ? (
                                                    <>
                                                        <h6>Joining fee:</h6>
                                                        <div className={styles.fee}>
                                                            <span>
                                                                {club.fees[0].value ? `$${club.fees[0].value}` : 'Free'}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (<div className={styles.feeFree}>FREE to join</div>)}
                                            </>
                                        )}
                                    </div>
                                    <Dialog open={showDialog} bindState={setShowDialog} size="xs" hideBtnOk={true} hideBtnCancel={true} onCancel={() => {setMembershipCost(null); setMemberLevel(null); setClientSecret(null)}}>
                                        {!membershipCost && (
                                            <>
                                                <h6 className="mb-4">Choose a membership option:</h6>
                                                {!club.disableFreeMemberships && (
                                                    <div>
                                                        <Radio
                                                            id="member_option"
                                                            value="free"
                                                            name="member_option"
                                                            label="Standard: Free"
                                                            action={handleMemberSelect}
                                                            divClick={false}
                                                        />
                                                    </div>
                                                )}
                                                {Array.isArray(club.fees) && club.fees.map((fee, feeIndex) => (
                                                    <div key={feeIndex}>
                                                        <span>
                                                            
                                                        </span>
                                                        <Radio
                                                            id="member_option"
                                                            name="member_option"
                                                            value={JSON.stringify(fee)}
                                                            label={`${fee.label}: ${fee.value ? `$${fee.value}` : 'Free'}`}
                                                            action={handleMemberSelect}
                                                            divClick={false}
                                                        />
                                                    </div>
                                                ))}
                                                <div className='mt-5 flex-center'>
                                                    <Button type="span" level="primary" onClick={() => assignMemberSelect()}>Confirm</Button>
                                                </div>
                                            </>
                                        )}

                                        {(membershipCost && stripeClientSecret && returnUrl) && (
                                            <>
                                                <h6>Make your payment</h6>
                                                {membershipCost && <div className={`pt-4 ${styles.paymentMemberDetails}`}>
                                                    <p>
                                                        <span>Membership:</span>
                                                        {orttoMemberLevel}
                                                    </p>
                                                    <h6>${membershipCost}</h6>
                                                </div>}
                                                <StripePayment clientSecret={stripeClientSecret} connectId={club.stripeConnectId} buttonLabel='Pay now' returnUrl={returnUrl} callback={handlePayment} cancel={() => setShowDialog(false)} />
                                            </>
                                        )}

                                        {(transactionSuccess && !status) && (
                                            <>
                                                <h6 className='mb-4'>Your payment is successful</h6>
                                                <p className={styles.message}>Please wait while we register you to the club...</p>
                                            </>
                                        )}
                                    </Dialog>
                                </>
                            )}

                            {club.socialLinks && (
                                <div className={styles.socialContainer}>
                                    {club.socialLinks.instagram && (
                                        <a href={club.socialLinks.instagram} className={`${styles.socialLinkContainer} ${styles.instagramIcon}`}>
                                            <span style={{display: 'none'}}>Instagram</span>
                                            <Icon symbol="instagram" />
                                        </a>
                                    )}
                                    {club.socialLinks.facebook && (
                                        <a href={club.socialLinks.facebook}  className={`${styles.socialLinkContainer} ${styles.facebookIcon}`}>
                                            <span style={{display: 'none'}}>Facebook</span>
                                            <Icon symbol="facebook" />
                                        </a>
                                        )}
                                    {club.socialLinks.twitter && (
                                        <a href={club.socialLinks.twitter} className={`${styles.socialLinkContainer}`}>
                                            <span style={{display: 'none'}}>Twitter</span>
                                            <Icon symbol="twitter" />
                                        </a>
                                    )}
                                    {club.socialLinks.linkedin && (
                                        <a href={club.socialLinks.linkedin} className={`${styles.socialLinkContainer}`}>
                                            <span style={{display: 'none'}}>Linkedin</span>
                                            <Icon symbol="linkedin" />
                                        </a>
                                    )}
                                    {club.socialLinks.pinterest && (
                                        <a href={club.socialLinks.pinterest} className={`${styles.socialLinkContainer}`}>
                                            <span style={{display: 'none'}}>Pinterest</span>
                                            <Icon symbol="pinterestMin" />
                                        </a>
                                    )}
                                    {club.socialLinks.discord && (
                                        <a href={club.socialLinks.discord} className={`${styles.socialLinkContainer}`}>
                                            <span style={{display: 'none'}}>Discord</span>
                                            <Icon symbol="discord" />
                                        </a>
                                    )}
                                    {club.socialLinks.website && (
                                        <a href={club.socialLinks.website} className={`${styles.socialLinkContainer}`}>
                                            <span style={{display: 'none'}}>Website</span>
                                            <Icon symbol="website" />
                                        </a>
                                    )}
                                </div>
                            )}
                        </div>
                        )}
                </div>
            </div>
            <div className={styles.mobileCtaButtonContainer}>
                {isLoggedIn ? (
                    <Button fullWidth level="primary" type="button" disabled={status !== false} onClick={() => joinClub()}>{status === true ? 'Joined' : 'Join Now'}</Button>
                ) : (
                    <Button fullWidth level="primary" type="a" href="/login/">Log in</Button>
                )}
            </div>
        </Container>


        </div>
    );
};

export default Club;
