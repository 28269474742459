import React, { useState } from 'react';
import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js' // CardElement
import { loadStripe } from '@stripe/stripe-js'
import Button from '../../atoms/Button/Button';
import * as styles from './StripePayment.module.css';

// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

let stripePromise = false;

const loadStripePromise = (connectId) => {
  if (stripePromise === false) {
    const headers = {};
    if (connectId) {
      headers.stripeAccount = connectId;
    }
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY, headers);
  }
}

const StripeForm = ({clientSecret, connectId, email, billingDetails, returnUrl, buttonLabel, callback, cancel}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [sending, setSending] = useState(false);
  const [displayError, setError] = useState(null);

  // const CARD_ELEMENT_OPTIONS = {
  //   style: {
  //     base: {
  //       color: "#32325d",
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: "antialiased",
  //       fontSize: "16px",
  //       "::placeholder": {
  //         color: "#aab7c4",
  //       },
  //     },
  //     invalid: {
  //       color: "#fa755a",
  //       iconColor: "#fa755a",
  //     },
  //   },
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSending(true);
    setError(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // const sObject = {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //   }
    // };

    // if (email) {
    //   sObject.receipt_email = email;
    // }

    // if (billingDetails) {
    //   sObject.payment_method.billing_details = billingDetails;
    // }

    // const result = await stripe.confirmCardPayment(clientSecret, sObject);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: "if_required"
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
      setError(result.error.message);
      setSending(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        callback(result.paymentIntent);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='pb-4'>
        {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}
        <PaymentElement />
      </div>
      {displayError && (<span className={styles.error}>{displayError}</span>)}
      <div className='flex-between'>
        <Button level='primary' size='tiny' type="button" disabled={!stripe || sending}>{sending ? 'Please wait...' : buttonLabel}</Button>
        {cancel && (
          <Button level='secondary' size='tiny' type='span' onClick={cancel}>Cancel</Button>
        )}
      </div>
    </form>
  )
}

const StripePayment = props => {
  const options = {
    // passing the client secret obtained in step 3
    clientSecret: props.clientSecret,
    // Fully customizable with appearance API.
    // appearance: {/*...*/},
  };

  const defaultConnectID = process.env.GATSBY_STRIPE_DEFAULT_CONNECT;

  loadStripePromise(defaultConnectID); // props.connectId - removed until clubs can KYC and get their own connect accounts

  return (
    <div className={ styles.StripePayment }>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm {...props} />
      </Elements>
    </div>
  );
};

export default StripePayment;
