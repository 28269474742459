import React from 'react';

// import LogoSVG from './asset'
import LogoSVG from './usu150-yellow'
// import LogoPNG from './asset.png'

import * as styles from './Logo.module.css';

const Logo = props => {
    const { siteTitle } = props

    return (
        <div className={styles.root}>
            {/* {LogoPNG && (
                <img src={LogoPNG} alt={siteTitle} />
            )} */}
            {LogoSVG && (
                <LogoSVG siteTitle={siteTitle} />
            )}
        </div>
    );
};

export default Logo