import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, navigate } from 'gatsby';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import ContentfulBlock from '../components/organisms/ContentfulBlock/ContentfulBlock';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import Container from '../components/atoms/Container/Container';
import { error } from '../helpers/toast';
// import { callScheduleHandler } from '../helpers/wordpress';

const ContentfulPage = ({ data }) => {
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const page = (data && data.page) || false;
    const pageTitle = (page && (page.metaTitle || page.name)) || '';
    const showBreadcrumb = page?.showBreadcrumb === true ? true : false;
    const headerBackgroundColor = page?.headerBackgroundColor?.hexCode || undefined;
    const bannerLinks = page?.bannerLinks || undefined;

    // useEffect(() => {
    //   callScheduleHandler();
    // }, []);

    useEffect(() => {
      if(page.loginRequired === true) {
          if (userChecked && !isLoggedIn && typeof window !== 'undefined') {
            error('Login first to access this page');
            navigate('/login');
        }
      }
    // eslint-disable-next-line
  }, [isLoggedIn, userChecked])

    return (
        <Layout disablePaddingBottom={page.disablePaddingBottom} headerBgColor={headerBackgroundColor} bannerLinks={bannerLinks}>
            <Helmet title={pageTitle} description={page.metaDescription || ''} />
            {showBreadcrumb &&
              <div style={{paddingTop: '40px'}}>
                <Container size={page.blocks[0].containerSize ? page.blocks[0].containerSize.toLowerCase() : ''}>
                  <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label: pageTitle}]} />
                </Container>
              </div>
            }
            {page.blocks && page.blocks.map((block, blockKey) => (
                <ContentfulBlock key={blockKey} block={block} size={null} data={data}/>
            ))}
        </Layout>
    );
};

ContentfulPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ContentfulPage;

export const query = graphql`
    query ContentfulPageByID($contentful_id: String!) {
        page: contentfulPages(contentful_id: { eq: $contentful_id }) {
          name
          slug
          metaTitle
          metaDescription
          metaCanonicalLink
          showBreadcrumb
          disablePaddingBottom
          loginRequired
          bannerMessage
          bannerLinks {
            message
            link {
              label
              location
              url
              target
            }
          }
          headerBackgroundColor {
            ... on ContentfulBrandColours {
              hexCode
              colourName
            }
          }
          blocks {
            ... on ContentfulBlockArticle {
              maxWidth
              alignment
              containerSize
              containerAlignment
              padding
              paddingBottom
              paragraphFontSize
              paragraphFontSizeMobile
              backgroundColor {
                hexCode
                supportingColour
              }
              ctaButton {
                label
                size
                sizeMobile
                marginTop
                link {
                  label
                  location
                  url
                  target
                }
                fontSize
                fontSizeMobile
              }
              content {
                raw
              }
              internal {
                type
              }
            }
            ... on ContentfulBanner {
              internal {
                type
              }
              label
              cta {
                label
                url
                location
                target
              }
            }
            ... on ContentfulCtaBanner {
              containerSize
              ctaLink {
                url
                label
              }
              content {
                raw
              }
              backgroundColor {
                hexCode
              }
              image {
                image {
                  file {
                    url
                  }
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulBlockCarousel {
              backgroundColour {
                hexCode
                supportingColour
              }
              carouselType
              containerSize
              slidesToDisplay
              title
              slides {
                ... on ContentfulGenericCard {
                  id
                  name
                  label
                  subtitle
                  link {
                    label
                    location
                    url
                    target
                  }
                  cardImage {
                    image {
                      file {
                        url
                      }
                    }
                  }
                }
                ... on ContentfulBlockSideCopy {
                  title
                  content {
                    raw
                  }
                  asset {
                    file {
                      url
                    }
                  }
                  orientation
                  widths
                  backgroundColour {
                    hexCode
                    supportingColour
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulImages {
                  image {
                    file {
                      url
                    }
                    internal {
                      type
                    }
                  }
                }
                ... on ContentfulBlockHero {
                  heroType
                  configuration
                  orientation
                  backgroundColour {
                    hexCode
                    supportingColour
                  }
                  backgroundAsset {
                    file {
                      url
                    }
                  }
                  content {
                    raw
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockCopy {
                  maxWidth
                  alignment
                  containerSize
                  containerAlignment
                  padding
                  backgroundColor {
                    hexCode
                    supportingColour
                  }
                  fontSize
                  fontSizeDesktop
                  fontSizeMobile
                  lineHeight
                  content {
                    raw
                  }
                  internal {
                    type
                  }
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulBlockCodedComponent {
              component
              containerSize
              internal {
                type
              }
            }
            ... on ContentfulBlockEventButton {
              ctaText
              internal {
                type
              }
            }
            ... on ContentfulBlockColumns {
              column1 {
                raw
              }
              column2 {
                raw
              }
              column3 {
                raw
              }
              backgroundColour {
                hexCode
                supportingColour
              }
              padding
              maxWidth
              containerSize
              internal {
                type
              }
            }
            ... on ContentfulBlockCopy {
              maxWidth
              alignment
              fontSize
              fontSizeDesktop
              fontSizeMobile
              lineHeight
              padding
              containerSize
              containerAlignment
              backgroundColor {
                hexCode
                supportingColour
              }
              ctaButton {
                label
                marginTop
                size
                sizeMobile
                link {
                  label
                  location
                  url
                  target
                }
                fontSize
                fontSizeMobile
              }
              content {
                raw
              }
              internal {
                type
              }
            }
            ... on ContentfulBlockDual {
              id
              internal {
                type
              }
              content {
                raw
              }
              backgroundColor {
                hexCode
              }
              image {
                image {
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulBlockHero {
              heroType
              configuration
              orientation
              backgroundColour {
                hexCode
                supportingColour
              }
              backgroundAsset {
                file {
                  url
                }
              }
              content {
                raw
              }
              internal {
                type
              }
            }
            ... on ContentfulBlockLinkContainer {
              name
              containerAlignment
              containerSize
              padding
              paddingBottom
              internal {
                type
              }
              linkCollection {
                ... on ContentfulMenuItems {
                  url
                  target
                  location
                  label
                }

              }
            }
            ... on ContentfulBlockSideCopy {
              title
              content {
                raw
              }
              asset {
                file {
                  url
                }
              }
              orientation
              widths
              backgroundColour {
                hexCode
                supportingColour
              }
              internal {
                type
              }
            }
            ... on ContentfulImageCollage {
              internal {
                type
              }
              caption {
                raw
              }
              backgroundColor {
                ... on ContentfulBrandColours {
                  hexCode
                  colourName
                }
              }
              centerImage {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              leftImage {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              rightImage {
                image {
                  title
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulImageGrid {
              internal {
                type
              }
              backgroundColor {
                ... on ContentfulBrandColours {
                  hexCode
                  colourName
                }
              }
              topLeft {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              bottomLeft {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              center {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              topRight {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              bottomRight {
                image {
                  title
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulBlockTrio {
              internal {
                type
              }
              title
              layout
              containerSize
              landscapePicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              portraitPicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              smallPicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              content {
                raw
              }
            }

            ... on ContentfulBlockFours {
              internal {
                type
              }
              landscapePicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              portraitFirstPicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              portraitSecondPicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              smallPicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              content {
                raw
              }
              title
              backgroundColour {
                hexCode
                supportingColour
              }
            }
            ... on ContentfulBlockOneContentTwoMediaLayout {
              internal {
                type
              }
              id
              name
              backgroundColor {
                hexCode
              }
              content {
                raw
              }
              button {
                label
                fontSize
                fontSizeMobile
                size
                sizeMobile
                marginTop
                link {
                  label
                  location
                  url
                  target
                }
              }
              largerPicture {
                image {
                  title
                  file {
                    url
                  }
                }
              }
              picture {
                  image {
                    title
                    file {
                      url
                    }
                  }
              }
            }
            ... on ContentfulBlockTimelineAccordion {
              internal {
                type
              }
              title
              timelineList {
                heading
                activities {
                  time
                  description {
                    raw
                  }
                }
              }
            }
            ... on ContentfulBlockGrid {
              internal {
                type
              }
              slidesToDisplayMobile
              slidesToDisplayWeb
              containerSize
              cardList {
                label
                link {
                  url
                }
                cardImage {
                  image {
                    file {
                      url
                    }
                  }
                }
              }
            }
            ... on ContentfulBlockColumnsParagraph {
              title
              columnParagraphOne {
                raw
              }
              columnParagraphTwo {
                raw
              }
              backgroundColour {
                hexCode
                supportingColour
              }
              internal {
                type
              }
            }

            ... on ContentfulBlockAccordion {
              id
              label
              layout
              containerSize
              items {
                heading
                content {
                  raw
                }
              }
              backgroundColour {
                hexCode
                supportingColour
              }
              internal {
                type
              }
            }
            ... on ContentfulBlockVisual {
              id
              name
              containerSize
              padding
              internal {
                type
              }
              image {
                image {
                  title
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulBlockVideo {
              id
              name
              containerSize
              padding
              internal {
                type
              }
              videoLink
            }

            ... on ContentfulBlockSpotify {
              id
              name
              containerSize
              padding
              internal {
                type
              }
              embedCode {
                embedCode
              }
            }
            ... on ContentfulFormTemplate {
              id
              internal{
                type
              }
              formTitle
              formSubtitle
              contentfulfields{
                ... on ContentfulFormFieldText{
                  label
                  type
                  required
                  orttoFieldName
                }
                ... on ContentfulFormFieldSelect{
                  label
                  type
                  options
                  required
                  orttoFieldName
                }
                
              }
            }

            ... on ContentfulBlockCsvClubSearch {
              id
              subnote
              clubData {
                file {
                  url
                  fileName
                  contentType
                }
              }
              title
              internal{
                type
              }
            }
          }
        }
    }
`;