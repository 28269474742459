import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';
import { decode } from 'he';
import { CSVLink } from "react-csv";
import AuthContext from '../../../context/AuthProvider';
import { dateFormat, hasStaffPermissions, persistLocation } from '../../../helpers/general';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import DialogMatter from '../../../components/atoms/Dialog/Dialog';
import Loader from "../../../components/atoms/Loader/Loader";
import Club from '../../../components/organisms/Club/Club';
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import { transformWpClub, wpApiNew, getHeader, wpAllNew } from '../../../helpers/wordpress';
import { getTags, getPerson } from '../../../helpers/ortto';
// import * as styles from '../my-usu/dashboard.module.css';
import * as styles from '../manage-screens.module.css';
import Button from '../../../components/atoms/Button/Button';

const Manage = () => {
  const auth = useContext(AuthContext);
  const [authed, setAuthed] = useState(null);
  const [init, setInit] = useState(false);
  const [authCompleted, setAuthCompleted] = useState(false);
  const [hasPermission, setPermission] = useState(false);
  const [clubList, setClubList] = useState(null);
  const [clubListExport, setClubListExport] = useState(null);
  const [totalClubs, setTotalClubs] = useState(0);
  const [totalMemberCount, setTotalMemberCount] = useState(0);
  const [clubMemberCounts, setClubMemberCounts] = useState([]);
  const [clubMemberDetail, setClubMemberDetail] = useState([]);
  const [clubMemberDetailReady, setClubMemberDetailReady] = useState(false);
  const [clubMemberDetailProgress, setClubMemberDetailProgress] = useState(0);
  const [clubMemberDetailTotal, setClubMemberDetailTotal] = useState(0);
  const [csvLoader, setCSVLoader] = useState(false);
  const [activeView, setActiveView] = useState(null);
  
  const [previewClub, setPreviewClub] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabLoading, setTabLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [quickSearchList, setQuickSearchList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const [bulkActions, setBulkActions] = useState([]);
  const [rowActions, setRowActions] = useState([
    {
      label: 'Edit',
      cta: data =>
        navigate('/account/manage-clubs/edit', {
          state: {
            clubId: data.id,
            clubName: `${data.club_name} (${data.status})`,
            forApproval: true,
          },
        }),
    },
    { label: 'Preview', cta: data => preview(data) },
  ]);

  const clubsQuickSearchData = useStaticQuery(graphql`
    query {
      clubs: allWpClub {
        nodes {
          club_name: title
          id: databaseId
          slug
          clubFieldsMain {
            abbr
          }
        }
      }

      pendingClubs: allWpPendingClub {
        nodes {
          club_name: title
          id: databaseId
          slug,
          status
        }
      }
    }
  `)

  useMemo(() => {
    if (authed === null && get(auth, 'state.userChecked')) {
      setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
      const permission = hasStaffPermissions(auth, [2, 3, 4]);
      setPermission(permission);
      setAuthCompleted(true);

      if (permission) {
        setBulkActions([{ label: 'Bulk delete', key: 'delete' }]);
        const _rowActions = [...rowActions , {
          label: 'Manage Members', cta: data => navigate('/account/manage-clubs/members', {
            state: {
              clubId: data.id,
              clubSlug: data.slug,
              clubName: `${data.club_name}`
            },
          }),
        },
        {
          label: 'Delete', cta: data => {setDeleteData([data]); setOpenConfirmDelete(true)}
        }
        ];

        setRowActions(_rowActions);
      }
    }
  }, [auth, authed, setAuthed, rowActions]);

  useEffect(() => {
    if (!quickSearchList && clubsQuickSearchData) {
        const qsClubList = clubsQuickSearchData?.clubs?.nodes.map((club) => club);
        const qsPendingClubList = clubsQuickSearchData?.pendingClubs?.nodes.map((club) => club).filter((club) => club.status !== 'ign');
        const qsList = [...qsClubList, ...qsPendingClubList];
        setQuickSearchList(qsList);
    }
  }, [clubsQuickSearchData, quickSearchList])

  const fetchClubList = useCallback(async (_activeView) => {

    /* Get total running club count */
    const clubsCount = await wpApiNew('getClubsList', {
      callId: 5,
      fieldSet: 2,
      limit: 1
    });
    const _totalClubs = getHeader(clubsCount.headers, 'x-wp-total');
    setTotalClubs(_totalClubs || 0);

    if (_activeView) {
      /* Get list of club tags with counts */
      /*** Disable club member counts temporarily while endpoint call takes longer than 10s to complete
      const _memberCounts = await getTags('club-');
      const memberCounts = _memberCounts.response.filter(t => t.name.startsWith('club-'));
      const breakdownData = memberCounts.map(t => ({
        name: t.name.replace(/club-(.*)/, '$1').split('-').map(w => w[0].toUpperCase() + w.substring(1)).join(' '),
        members: t.count
      }))
      setClubMemberCounts(breakdownData);
      const _totalMemberCount = memberCounts.map(t => t.count || 0).reduce((sum, a) => sum + a, 0);
      setTotalMemberCount(_totalMemberCount || 0);
      ***/
      // setLoading(true);
      
      setClubMemberCounts([]);
      setTotalMemberCount('-');

      let clubsPendingApproval = false;
      if (_activeView === 'pending') {
        clubsPendingApproval = await wpAllNew('getClubsList', {
          callId: 2,
          fieldSet: 99,
          limit: 100
        });
      }
      // console.log("Pending", clubsPendingApproval);

      let clubsCurrentIgmAgm = false;
      let clubsCurrentIgm = false;
      if (_activeView === 'meetings') {
        clubsCurrentIgmAgm = await wpAllNew('getClubsList', {
          callId: 3,
          fieldSet: 99,
          limit: 100,
        });

        clubsCurrentIgm = await wpAllNew('getClubsList', {
          callId: 6,
          fieldSet: 99,
          limit: 100,
        });
      }
      // console.log("AGM/IGM", clubsCurrentIgmAgm);

      let clubsStarting = false;
      if (hasPermission && _activeView === 'new') {
        clubsStarting = await wpAllNew('getClubsList', {
          callId: 4,
          fieldSet: 99,
          limit: 100
        });
        // console.log("Starting", clubsStarting);
      }

      const clubs = [];
      if (clubsPendingApproval && clubsPendingApproval.status === 200) {
        clubs.push(...clubsPendingApproval.response);
      }
      if (clubsCurrentIgmAgm && clubsCurrentIgmAgm.status === 200) {
        clubs.push(...clubsCurrentIgmAgm.response);
        clubs.push(...clubsCurrentIgm.response);
      }
      if (clubsStarting && clubsStarting.status === 200) {
        clubs.push(...clubsStarting.response);
      }

      // Due to dual API above there is an instance when a club are fetch for both and results to duplicate in final club list - JR
      const historyIds = [];

      const uniqClubs = clubs.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.id === current.id)) {
          if ((current.status === 'publish' || current.status === 'ign') && 
            current.acf.current_igmagm_history_record && current.acf.current_igmagm_history_record !== null) {
              console.log(current);
            historyIds.push(current.acf.current_igmagm_history_record.ID)
          }
          accumulator.push(current);
        }
        return accumulator;
      }, []);

      const historyRecordsResponse = _activeView === 'meetings' && historyIds && historyIds.length > 0 ? 
        await wpAllNew('getClubHistoryRecordsById', {historyIds: historyIds.join(','), limit: 100})
        : false;
      const historyRecords = (historyRecordsResponse && historyRecordsResponse.status === 200) ? historyRecordsResponse.response : [];

      if (uniqClubs.length > 0) {
        const _clubList = uniqClubs.map(async club => {
          const ogStatus = club.status;
          const statuses = {
            publish: "Pending changes",
            applied: "New application",
            compliant: "Signatures Collected",
            engaged: "IGM completed",
            agm: "AGM completed",
            inprogress: "AGM running",
            igminprogress: "IGM running",
            ign: "IGM running",
            error: "AGM misconfigured",
            igmerror: "IGM misconfigured"
          }
          
          if (club.status === 'ign' && !club.acf.current_igmagm_history_record) {
            club.status = 'igmerror';
          }

          if (club.acf.current_igmagm_history_record && club.acf.current_igmagm_history_record !== null) {
            const history = historyRecords.find(hr => hr.id === club.acf.current_igmagm_history_record.ID);
            club.status = club.status === 'publish' ? 'agm' : club.status;
            
            if (history && history.acf.status === 'running') {
              club.status = club.status === 'ign' ? 'igminprogress' : 'inprogress';
            } else if (history && history.acf.status === 'finished') {
              club.status = club.status === 'ign' ? 'igmerror' : 'error';
            }
          }
          
          // if (club.status === 'publish' && club.acf.current_igmagm_history_record && club.acf.current_igmagm_history_record !== null) {
          //   club.status = 'agm';
          //   // const history = await wpApiNew('getClubHistoryData', {
          //   //   clubId: club.id,
          //   //   historyId: club.acf.current_igmagm_history_record.ID,
          //   //   fieldSet: 99
          //   // });
          //   const history = historyRecords.find(hr => hr.id === club.acf.current_igmagm_history_record.ID);
          //   // console.log("history: ", history);
          //   // if (String(history.status).startsWith('2')) {
          //     if (history.acf.status === 'running') {
          //       club.status = 'inprogress';
          //     } else if (history.acf.status === 'finished') {
          //       club.status = 'error';
          //     }
          //   // }
          // }
          
          return {
            ...club,
            club_name: decode(club.title.rendered),
            status: statuses[club.status],
            status_code: club.status,
            og_status: ogStatus
          }
        });

        Promise.all(_clubList).then(_list => {
          const staffLevel = Number(get(auth, 'state.usu.USUStaffType', get(auth, 'USUStaffType', false)));

          if (_activeView === 'pending') {
            _list = _list.filter((list) => list.status_code === 'publish');
          } 
          
          // else if (_activeView === 'new') {
          //   _list = _list.filter((list) => list.status_code === 'applied' || list.status_code === 'compliant');
          // } else if (_activeView === 'meetings') {
          //   _list = _list.filter((list) => list.status_code !== 'publish' && list.status_code !== 'applied' && list.status_code !== 'compliant');
          // }

          if (staffLevel === 2) {
            setClubList(_list.filter((list) => list.status_code !== 'compliant' && list.status_code !== 'applied'));
          } else {
            setClubList(_list);
          }

          const clubExport = [];
          _list.map((list) => {
            clubExport.push({
              'title': list.acf.full_title,
              'status': list.status,
              'club_updated': dateFormat(list.modified),
              'club_execs': JSON.stringify(list.acf.club_execs),
              'new_club_roles': JSON.stringify(list.acf.club_roles),
              'fee': JSON.stringify(list.acf.fees),
              'submitted': list.acf.submitted,
              'submitted_by': list.acf.submitted_by,
              'vendor_code': list.acf.vendor_code,
              'verified': list.acf.verified,
              'verified_by': list.acf.verified_by,
            });
            
            return list;
          })
          setClubListExport(clubExport);
        })
      } else {
        // Failed fetch of club data
        setClubList(false);
      }
      setTabLoading(false);
    }
    setLoading(false);
  }, [hasPermission, auth]);

  useMemo(() => {
    if (authCompleted && !loading && clubList === null && !init) {
      setInit(true);
      fetchClubList(activeView);
    }
  }, [init, authCompleted, loading, clubList, fetchClubList, activeView]);

  const preview = async data => {
    const previewData = await wpApiNew('getClubData', {
      clubId: data.id,
      fieldSet: 99
    });
    if (String(previewData.status).startsWith('2') && previewData.response) {
      const event = transformWpClub(previewData.response);
      setPreviewClub(event);
    }
  };

  const deleteClubs = async () => {
    setOpenConfirmDelete(false);
    const _deleteData = [...deleteData];
    setDeleteData([]);
    const promises = _deleteData.map(async club => {
      return await wpApiNew('deleteClub', {
        clubId: club.id
      });
    });

    await Promise.all(promises);

    setClubList(null);
    fetchClubList(activeView);
  }

  const handleBulkActions = (key, data) => {
    switch (key) {
      case "delete":
        setDeleteData(data); 
        setOpenConfirmDelete(true);
        break;

      default:
        // do nothing
        break;
    }
  }

  const handleClubSearch = (id, value) => {
    setSearchQuery(value);
    actionQuickSearch(value);
  }

  const actionQuickSearch = (value) => {
    if (value.length >= 3) {
      const results = quickSearchList.filter(club => {
        const name = decode(`${club.club_name} (${club.clubFieldsMain?.abbr || 'PENDING'})`);
        return name.toLowerCase().indexOf(value.toLowerCase()) > -1
      });
      setSearchResults(results);
    }
  }

  const fetchDetailList = () => {
    setCSVLoader(true);
    let progress = 0;

    wpAllNew('getClubsStaffList', {
      filter: 'all=true',
      limit: 100,
      fieldSet: 1
    }).then(clubs => {
      if (String(clubs.status).startsWith('2') && clubs.response) {
        setClubMemberDetailTotal(clubs.response.length);
        const membersList = [];
        const loop = clubs.response.map(club => {
          return getTags(`club-${club.slug}`).then(result => {
            if (
              result.status === 200 &&
              result.response &&
              result.response.length > 0
            ) {
              const findClub = result.response.find(
                fclub => fclub.name === `club-${club.slug}`
              );
              // Successfully found
              if (findClub) {
                return getPerson(
                  ['first', 'last', 'email', 'Tags', 'MemberNumber'],
                  findClub.id,
                  'tag_id'
                ).then(memberList => {
                  if (
                    memberList.status === 200 &&
                    'contacts' in memberList.response &&
                    Array.isArray(memberList.response.contacts)
                  ) {
                    progress = progress + 1;
                    // console.log(progress);
                    setClubMemberDetailProgress(progress);
                    return memberList.response.contacts.map(member => {
                      membersList.push({
                        member_number: member.fields['str:cm:membernumber'] || '0',
                        club_id: club.id,
                        club_tag: `club-${club.slug}`
                      });
                      return true;
                    });
                  } else {
                    console.log("Person fetch worked with error");
                    return false;
                  }
                }).catch(() => {
                  console.log("Catch person fetch");
                  return false
                });
              } else {
                console.log("Club not found", `club-${club.slug}`, result.response);
                return true;
              }
            } else {
              console.log("Tags fetch worked with error");
              return false;
            }
          }).catch(() => {
            console.log("Catch tags fetch");
            return false
          });
        });
        
        Promise.all(loop).then(() => {
          // console.log(membersList);
          setCSVLoader(false);
          setClubMemberDetailReady(true);
          // createJSON(membersList, 'club-members-detail');
          JSONDownload(membersList, 'club-members-detail');
        });
      } else {
        console.log("Club fetch worked with error");
        setCSVLoader(false);
      }
    }).catch(() => {
      console.log("Catch club fetch");
      setCSVLoader(false);
    });
  }
  
  const triggerViewChange = (view) => {
    if (view !== activeView) {
      setTabLoading(true);
      setClubList(null);
      setActiveView(view);
      fetchClubList(view);
    }
  }

  const JSONDownload = (data) => {
    const jsonData = new Blob([JSON.stringify(data)], {type: 'text/json'});
    const href = URL.createObjectURL(jsonData);
    setClubMemberDetail(href);
  }

  return (
    <>
      {authed ? (
        <div className={`${styles.root}`} style={{opacity: loading ? '0.5' : '1'}}>
          {loading ? (
            <>Fetching clubs. Please wait...</>
          ) : (
            <>
            
              {/**
               * Club search
               **/}
              <h6 className='mb-4'>Search for a club</h6>
              <div className={`${styles.root} ${styles.withBg} mb-5`}>
                <FormInputField placeholder="Search club name" handleChange={handleClubSearch} />
                <div>
                  {(searchQuery && searchQuery.length >= 3) && (
                    <>
                      {searchResults.length === 0 && (
                        <p>No clubs found</p>
                      )}

                      {searchResults.length > 0 && (
                        <DataTable
                          tableData={searchResults}
                          headingKeys={[
                            { label: 'Club name', data_key: 'club_name', sortable: true },
                          ]}
                          rowActions={[
                            {
                              label: 'Edit',
                              cta: data =>
                                navigate('/account/manage-clubs/edit', {
                                  state: {
                                    clubId: data.id,
                                    clubName: `${data.club_name}`,
                                    forApproval: true,
                                  },
                                }),
                            },
                            {
                              label: 'Manage members',
                              cta: data =>
                                navigate('/account/manage-clubs/members', {
                                  state: {
                                    clubId: data.id,
                                    clubSlug: data.slug,
                                    clubName: `${data.club_name}`
                                  },
                                }),
                            },
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>

              {/**
               * Clubs withs statuses or flags that require staff attention 
               **/}
              <h6 className='mb-4'>Clubs requiring attention</h6>
              <div>
                <span className={`${styles.tab}${activeView === 'pending' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('pending')}>Pending Changes</span> 
                <span className={`${styles.tab}${activeView === 'new' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('new')}>New Clubs</span>
                <span className={`${styles.tab}${activeView === 'meetings' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('meetings')}>Meetings</span>
              </div>
              {tabLoading ? (
                <div className='mt-4'>Loading...</div>
              ) : (
                <DataTable
                  tableData={clubList}
                  exportData={clubListExport}
                  bulkActions={bulkActions}
                  allowExport={true}
                  handleBulkAction={handleBulkActions}
                  headingKeys={[
                    { label: 'Club name', data_key: 'club_name', sortable: true },
                    { label: 'Status', data_key: 'status', filterable: true },
                    { label: 'Updated', data_key: 'modified', sortable: true, format: 'dateTime' },
                  ]}
                  rowActions={rowActions}
                  bulkAction={true}
                  emptyMessage={activeView ? 'No records found...' : 'Select a tab to view clubs.'}
                />
              )}
              {loading && <div className={styles.loaderContainer}><Loader /></div>}
              <DialogMatter
                size='lg'
                title='Preview Club'
                hideBtnCancel={true}
                onOk={() => setPreviewClub(false)}
                open={typeof previewClub === 'object'}>
                {previewClub && <Club club={previewClub} events={[]} />}
              </DialogMatter>
              <DialogMatter size='xs' open={openConfirmDelete} okBtnText="Confirm" onOk={() => deleteClubs()} onBtnCancel={() => setOpenConfirmDelete(false)}>
                Are you sure you want to delete {deleteData.length} club{deleteData.length > 1 ? 's' : ''}?
              </DialogMatter>

              {/**
               * Clubs analytics
               **/}
              <h6 className='mb-4 mt-6'>Analytics</h6>
              <div className={`${styles.subroots} grid grid-50`}>
                  <div className={`${styles.root} ${styles.withBg}`}>
                    <div className={styles.displayNumberContainer} >
                      <span className={styles.displayNumber}>
                        {totalClubs} &nbsp;
                      </span>
                      <strong>Total running club{totalClubs > 1 && 's'}</strong>
                    </div>
                  </div>

                  <div className={`${styles.root} ${styles.withBg}`}>
                    <div className={styles.displayNumberContainer} >
                      <span className={styles.displayNumber}>
                        {totalMemberCount} &nbsp;
                      </span>
                      <strong>Total club member{totalMemberCount > 1 && 's'}</strong>
                    </div>
                    <span className={styles.analyticActionItem}>
                      Download:&nbsp;
                      <CSVLink data={clubMemberCounts} filename={`club-count-summary`}>
                        Summary per club
                      </CSVLink>
                      &nbsp;|&nbsp;
                      <Button type="span" link={true} onClick={fetchDetailList}>Detailed per member</Button>
                    </span>
                    {csvLoader && (
                      <div className={styles.loaderCover}><span><Loader /><strong>Please wait - fetching {clubMemberDetailProgress} of {clubMemberDetailTotal}</strong></span></div>
                    )}
                    {clubMemberDetailReady && (
                        <div className={styles.loaderCover}><a href={clubMemberDetail} download="club-members-detail.json">View result</a></div>
                    )}
                  </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching data */}
          {authed === null && <div>Fetching data</div>}
          {/* No data found */}
          {authed === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/'>
                <u>dashboard</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const USUClubManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - USU Management'
      bgRaw
      title='Manage Clubs'
      breadcrumbTitle='Manage Clubs'>
      <Manage location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default USUClubManagementOutput;

