import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Container from '../../atoms/Container/Container';
import * as styles from './ContentfulBlockCsvClubSearch.module.css';
import Icon from '../../atoms/Icon/Icon';
import { Link } from 'gatsby';

const ContentfulBlockCsvClubSearch = props => {
    const { subnote, title, clubData } = props;

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [focused, setIsFocused] = useState(false);
    const searchInput = useRef();

    useEffect(() => {
        const fetchCsv = async () => {
            try {
                const response = await fetch(clubData.file.url);
                const csvText = await response.text();
                const parsedData = parseCsv(csvText);
                // console.log(parsedData);
                setData(parsedData);
            } catch (error) {
                console.error("Error fetching CSV:", error);
            }
        };
        
        if (clubData.file.url) {
            fetchCsv();
        } else {
            console.log('No data found for CSV');
        }
    }, [clubData]);
    
    const parseCsv = (csvText) => {
        const rows = csvText.split("\n");
        const headers = rows[0].split(",");
        const data = rows.slice(1).map((row) => {
            const values = row.split(",");
            return headers.reduce((acc, header, index) => {
                acc[header.trim()] = values[index]?.trim();
                return acc;
            }, {});
        });
        return data;
    };

    const submitHandler = e => {
        e.preventDefault();
    }

    const actionSuggestSearch = term => {
        console.log(term);
        setIsFocused(true);
        setSearchTerm(term);
        suggestiveSearch(term);
    };

    const suggestiveSearch = _.debounce(term => {
        if (term.length >= 2) {
            setLoading(true);

            const filtered = data.filter((item) => {
                return (
                    (item.club_name !== '') && (
                        (item.club_name && item.club_name.toLowerCase().includes(term.toLowerCase())) ||
                        (item.abbr && item.abbr.toLowerCase().includes(term.toLowerCase()))
                    )
                );
            });
            
            setSuggestions(filtered);
            setLoading(false)
        } else {
            setSuggestions([]);
        }
    }, 200);

    const suggestionsCount = () => {
        let merger = [];
        suggestions.map(suggestion => {
            const items = suggestion.all;
            merger = merger.concat(items);

            return true;
        });

        return {count: merger.length, items: merger}
    }

    const focusInput = _.debounce(() => {
        searchInput.current?.focus();
    }, 310)

    useEffect(() => {
        if (searchInput.current && !focused && document.activeElement === searchInput.current) {
            focusInput();
        }
    }, [focusInput, focused]);

    return (
        <div className={styles.contentfulBlockCsvClubSearch}>
            <Container>
                <div className={`${styles.formContainer}`}>
                    <div className={styles.helperContainer}>
                        <h3>{title}</h3>
                        <span>{subnote}</span>
                    </div>
                    <form
                        className={`${styles.form}`}
                        onSubmit={submitHandler}
                    >
                        <div className={styles.inputContainer}>
                            <input
                                id='csv_search'
                                name='csv_search'
                                className={styles.input}
                                ref={searchInput}
                                type="text"
                                placeholder="Type here..."
                                onChange={e => actionSuggestSearch(e.target.value)}
                                onFocus={e => actionSuggestSearch(e.target.value)}
                                onBlur={() => setIsFocused(false)}
                                value={searchTerm}
                            ></input>
                            <button 
                                onClick={()=>{
                                    setSuggestions([]);
                                    setSearchTerm('')
                                }} 
                                className={`${styles.button} ${searchTerm === '' ? styles.hideSearch : ''} `} 
                                type="button">
                                <Icon symbol="cross" />
                            </button>
                        </div>
                    </form>

                    {searchTerm && suggestions.length > 0 && <div className={styles.showingResults}>
                        {searchTerm.length > 1 && <h4>Showing {suggestionsCount().count} results for <b>‘{searchTerm}’</b></h4>}
                    </div>}
                    {suggestions.length === 0 && loading === false && searchTerm.length >= 3 &&
                        <div>
                            <h4>No Clubs found</h4>
                        </div>
                    }
                    {suggestions.length > 0 && (
                        <div className={styles.suggestions}>
                            {suggestions && suggestions.map((item, index) => (
                                <div key={index} className={styles.searchResultRow}>
                                    <div className={styles.details}>
                                        <code className={`${styles.code} clubs`}>{'category' in item && item.category !== '' ? item.category : 'CLUB'}</code>
                                        {item.link ? (
                                            <a href={item.link}>
                                                <h5>
                                                    {item.abbr ? `${item.abbr} - ${item.club_name}` : item.club_name}
                                                    {/* {item.abbr} {item.club_name} */}
                                                    <span className={styles.stallBadge}>Stall {item.stall_number}</span>
                                                </h5>
                                            </a>
                                        ) : ( 
                                            <h5>
                                                {item.abbr ? `${item.abbr} - ${item.club_name}` : item.club_name}
                                                {/* {item.abbr} {item.club_name} */}
                                                <span className={styles.stallBadge}>Stall {item.stall_number}</span>
                                            </h5>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <h4 className={styles.noResultLabel}>
                    If you can’t find your Club, they might not have a stall at Welcome Fest this sem. <br/>Check out the full list of Clubs <Link to={'/clubs'} className={styles.underlineLink}>here ↗</Link>
                </h4>
            </Container>
        </div>
    );
};

export default ContentfulBlockCsvClubSearch;

