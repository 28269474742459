import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

// import AddNotification from '../AddNotification'; // Replace with JAMM Notification
import Brand from '../../../atoms/Brand/Brand';
import Icon from '../../../atoms/Icon/Icon';
import CartContext from '../../../../context/CartProvider';
import ContentfulContext from '../../../../context/ContentfulProvider';
import Container from '../../../atoms/Container/Container';
// import Config from '../../../../config.json';
import Drawer from '../../../atoms/Drawer/Drawer';
// import ExpandedMenu from '../../../molecules/ExpandedMenu/ExpandedMenu';
// import FormInputField from '../../../atoms/FormInputField/FormInputField';
import MiniCart from '../../../molecules/DemoMiniCart/MiniCart';
import SearchOverlay from '../../../organisms/SearchOverlay/SearchOverlay';
// import sampleLinks from './header2.json';
import { useWindowDimensions } from '../../../../helpers/general';
// import MobileNavigation from '../MobileNavigation'; // Replace with single coded responsive menu

import * as styles from './Header.module.css';
import SmartLink from '../../../atoms/SmartLink/SmartLink';
import AccountPageMenu from '../../../molecules/AccountPageMenu/AccountPageMenu';
import AuthContext from '../../../../context/AuthProvider';

import { dataLayerPush } from '../../../../helpers/general';


/**
 * Custom hook to listen to outside clicks of an element
 */
const useOutSideClickListener = (ref, clicked) => {
  useEffect(() => {
    const outsideClickHandler =(event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        clicked();
      }
    }
    document.addEventListener("mousedown", outsideClickHandler);
    return () => {
      document.removeEventListener("mousedown", outsideClickHandler);
    };
  }, [ref, clicked]);
}


const Header = (prop) => {

  const { setHeaderHeight, headerBgColor } = prop;
  const defaultHeaderBgColor = 'var(--standard-white)'; // var(--standard-light-grey)

  const [showMiniCart, setShowMiniCart] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
   const [showMenu, setShowMenu] = useState(false);
   const menuRef = useRef()

  const [showSearch, setShowSearch] = useState(false);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(defaultHeaderBgColor);
  const [onTop, setOnTop] = useState(true);
  const [showNavMenu, setShowNavMenu] = useState(false);

  const ctx = useContext(CartContext) || { state: { cart: {} } };
  const siteConfig = useContext(ContentfulContext);
  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const usuUserData = auth && auth.state.usu;

  const mainHeaderLinks = useMemo(() => {
    return siteConfig?.headerMenu?.map(links => {
      if(isLoggedIn && links.label ==='Join') return null;
      return links
    }).filter(Boolean) || []
  } , [siteConfig ,isLoggedIn]);
  const navigationLinks = useMemo(() => (siteConfig && siteConfig?.navigationMenu?.menuGroups?.map(links => links)) || [], [siteConfig]);

  const { state } = ctx;
  const { cart } = state;
  const { lineItems } = cart;
  const { numberItems } = state.cart;

  const { width } = useWindowDimensions();

  const viewCartDataLayer = (lineItems) => {

    if (lineItems) {
      const allCartItems = [];

      if (lineItems.custom_items && lineItems.custom_items.length) {
        lineItems.custom_items.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (lineItems.digital_items && lineItems.digital_items.length) {
        lineItems.digital_items.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (lineItems.digital_items && lineItems.gift_certificates.length) {
        lineItems.gift_certificates.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (lineItems.digital_items && lineItems.physical_items.length) {
        lineItems.physical_items.forEach((item, i) => {
          allCartItems.push(item);
        });
      }

      if (allCartItems.length) {
        dataLayerPush('viewCart', null, allCartItems);
      }
    }
  }

  // let headerBackgroundColor = 'var(--standard-light-grey)';
  // if(showNavMenu === true) {
  //   headerBackgroundColor = 'var(--standard-black)';
  // } else if(headerBgColor !== undefined){
  //   headerBackgroundColor = headerBgColor;
  // }

  // const searchRef = createRef();
  // const bannerMessage = 'Free shipping worldwide';
  // const searchSuggestions = ['Oversize sweaters', 'Lama Pajamas', 'Candles Cinnamon'];

  // const handleHover = (navObject) => {
  //   if(navObject.category) {
  //     setShowMenu(true);
  //     setMenu(navObject.category);
  //     setShowSearch(false);
  //   } else {
  //     setMenu(undefined);
  //   }
  //   setActiveMenu(navObject.menuLabel);
  // }

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   navigate(`/search?q=${search}`);
  //   setShowSearch(false);
  // }

  // disable active menu when show menu is hidden
  // useEffect(() => {
  //   if(showMenu === false) setActiveMenu(false);
  // }, [showMenu])

  // hide menu onscroll

  useEffect(() => {
    const onScroll = () => {
      setShowNavMenu(false);

      if(window.pageYOffset === 0) {
        setOnTop(true);
      } else {
        setOnTop(false);
      }

      if(headerBgColor !== undefined) {
        if(window.pageYOffset > 0) {
          setHeaderBackgroundColor(defaultHeaderBgColor);
        } else if(headerBgColor !== undefined) {
          setHeaderBackgroundColor(headerBgColor);
        }
      }
    }
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  }, [setHeaderHeight, width]);

  //listen for show search and delay trigger of focus due to CSS visiblity property
  // useEffect(() => {
  //   if(showSearch === true){
  //     setTimeout(() => {
  //       searchRef.current.focus();
  //     }, 250)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[showSearch])

  useEffect(() => {
    if (typeof window != 'undefined' && window.document) {
        if (showMiniCart === true) {
          document.body.classList.add('disable-scrolling');
          viewCartDataLayer(lineItems);
      } else {
          document.body.classList.remove('disable-scrolling');
      }
    }
  },[showMiniCart, lineItems])

  useEffect(() => {
    if(showNavMenu === true) {
      setShowMenu(false);
    }
  }, [showNavMenu])

  useEffect(() => {
    if(showMenu === true) {
      setShowNavMenu(false);
    }
  }, [showMenu])

  useEffect(() => {
    if(headerBgColor !== undefined) {
      setHeaderBackgroundColor(headerBgColor);
    }

    window.addEventListener('keydown', escapeHandler);
    return () => window.removeEventListener('keydown', escapeHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const escapeHandler = (e) => {
    if(e?.keyCode === undefined) return;
    if(e.keyCode === 27) setShowSearch(false);
  }

  const headerRef = useRef(null);


  useOutSideClickListener(menuRef, () => {
    setShowMenu(false);
  });

  return (
    <div className={`${styles.root} ${onTop === true ? '' : styles.shadow}`} style={{backgroundColor: showNavMenu === true ? 'var(--standard-black)' : 'var(--standard-white)'}}> 
    {/* headerBackgroundColor */}
      {/* <div className={styles.headerMessageContainer}>
        <span>{bannerMessage}</span>
      </div> */}
      <div
        className={`${styles.headerLinksWrapper} ${showNavMenu === false ? styles.hideHeader : styles.showHeader}`}
        style={{top: headerRef?.current?.clientHeight !== undefined ? headerRef?.current?.clientHeight - 4 : ''}}>
        {/* offset top by a small amount; prevents white line */}
        <Container size={'large'}>
          <div className={styles.headerLinkContainer}>
            {navigationLinks.map((linkCollection,index) => {
              return(<div key={index} className={styles.headerLinkCollection}>
                {
                  linkCollection?.menuItems?.map((link, linkIndex) => {
                    return <SmartLink key={linkIndex} menuLink={link} linkStyle={styles.headerLink} />
                  })
                  // linkCollection?.menuItems?.map((link) =>
                  // <Link key={link.label} className={styles.headerLink} to={link.link}>
                  //   {link.label}
                  // </Link>)
                }
              </div>)
            })}
          </div>
        </Container>
      </div>
      <Container size={'xl'}>
          {/* header container */}
          <div className={`${styles.header} ${showNavMenu === true ? styles.showNavHeader : ''}`} ref={headerRef}>
            <div className={`${styles.linkContainer}`}>
              <nav role={'presentation'} className={`${showNavMenu === true ? styles.white : ''}`} >
                {mainHeaderLinks.map((link, index) => <SmartLink activeLinkStyle={styles.activeLink} linkStyle={styles.link} key={index} menuLink={link} />)}
              </nav>
            </div>
            <div className={`${styles.mobileLeftContainer} ${showNavMenu === true ? styles.hide : ''}`}>
              <div className={styles.iconContainer} role={'presentation'} onClick={() => setShowNavMenu(!showNavMenu)}>
                <Icon symbol={`${showNavMenu === true ? 'cross' : 'burger'}`}></Icon>
              </div>
              <div className={styles.iconContainer} role={'presentation'} onClick={() => {
                  setShowSearch(!showSearch);
                }}>
                <Icon symbol={'search'}></Icon>
              </div>
            </div>
            {/* <div role={'presentation'} onClick={() => {
              setMobileMenu(!mobileMenu);
              // setDepth(0);
              }} className={styles.burgerIcon}>
                <Icon symbol={`${mobileMenu === true ? 'cross' : 'burger'}`}></Icon>
            </div> */}
            <Brand color={`${showNavMenu === true ? 'var(--standard-white)' : 'var(--standard-black)'}`}/>
            <div className={`${styles.actionContainers} ${showNavMenu === true ? styles.white : ''}`}>
              <div
                className={`${styles.iconContainer} ${showNavMenu === true && width < 825 ? styles.show :''}`}
                role={'presentation'}
                onClick={() => setShowNavMenu(!showNavMenu)}
              >
                <Icon symbol={`${showNavMenu === true ? 'cross' : 'burger'}`}></Icon>
              </div>
              <div className={`${styles.iconContainer} ${styles.bagIconContainer} ${showNavMenu === true && width < 825 ? styles.hide :''}`}
                role={'presentation'} onClick={()=> {
                setShowMiniCart(true);
                setMobileMenu(false);
              }}>
                <Icon symbol={'bag'}></Icon>
                {numberItems !== 0 && <div className={`${styles.bagNotification}`} style={{backgroundColor: showNavMenu === true ? 'var(--standard-black)' : headerBackgroundColor}}>
                  <div className={styles.circleNotification} style={{backgroundColor: showNavMenu === true ? 'var(--standard-white)' : ''}}/>
                </div> }

                {/* <div className={styles.notificationContainer}>
                  <AddNotification />
                </div> */}
              </div>
              <div ref={menuRef} role={'presentation'} onClick={() => {
                // setShowNavMenu(false);
                setShowMenu(!showMenu);
              }} className={`${styles.iconContainer} ${showNavMenu === true && width < 825 ? styles.hide :''} ${styles.iconNav}`}>
                {isLoggedIn === true ?
                <div className={styles.avatarContainer} style={{backgroundColor: showNavMenu === true ? 'var(--standard-white)' : 'var(--standard-black)'}}>
                  <span className={styles.avatarInitial} style={{color: showNavMenu === true ? 'var(--standard-black)' : 'var(--standard-white)'}}>
                    {auth?.state?.object?.first_name.charAt(0)}{auth?.state?.object?.last_name.charAt(0)}
                  </span>
                </div> : <Icon symbol={'user'}></Icon>}
                {showMenu &&
                <div className={styles.iconMenu}>
                  <AccountPageMenu isLoggedIn={isLoggedIn} usuUserData={usuUserData} />
                </div>
                }
              </div>
              {/* <div role={'presentation'} onClick={()=>navigate('/account/favorites')} className={`${styles.iconContainer} ${styles.hideOnMobile}`}><Icon symbol={'heart'}></Icon></div> */}
              <div className={styles.iconContainer} role={'presentation'} onClick={() => {
                  setShowSearch(!showSearch);
                }}>
                <Icon symbol={'search'}></Icon>
              </div>
            </div>
          </div>

          {/* search container */}
          {/* <div className={`${styles.searchContainer} ${showSearch === true ? styles.show : styles.hide}`}>
            <h4>What are you looking for?</h4>
            <form className={styles.searchForm} onSubmit={(e) => handleSearch(e)}>
              <FormInputField
                ref={searchRef}
                icon={'arrow'}
                id={'searchInput'}
                value={search}
                placeholder={''}
                type={'text'}
                handleChange={(_, e) => setSearch(e)}
              />
              </form>
              <div className={styles.suggestionContianer}>
                {searchSuggestions.map((suggestion, index) =>
                  <p role={'presentation'} onClick={()=> {
                    setShowSearch(false);
                    navigate(`/search?q=${suggestion}`)
                  }} key={index} className={styles.suggestion}>
                    {suggestion}
                  </p>)}
              </div>
              <div role={'presentation'} onClick={(e)=> {
                e.stopPropagation();
                setShowSearch(false);
                }} className={styles.backdrop}></div>
          </div> */}
        </Container>

        {/* menu container */}
        {/* <div
          role={'presentation'}
          onMouseLeave={() => setShowMenu(false)}
          onMouseEnter={() => setShowMenu(true)}
          className={`${styles.menuContainer} ${showMenu === true ? styles.show : ''}`}>
          <Container size={'large'} spacing={'min'}>
            <ExpandedMenu menu={menu} />
          </Container>
        </div> */}

        {/* minicart container */}
        <Drawer hideCross visible={showMiniCart} close={() => setShowMiniCart(false)}>
          <MiniCart close={() => setShowMiniCart(false)}/>
        </Drawer>

        {/* mobile menu */}
        <div className={styles.mobileMenuContainer}>
          <Drawer
            hideCross
            top={'98px'}
            isReverse
            visible={mobileMenu}
            close={() => setMobileMenu(false)}
          >
            {/* <MobileNavigation close={() => setMobileMenu(false)} /> */}
            TODO: To be replaced
          </Drawer>
        </div>

        <SearchOverlay
          showing={showSearch}
          close={() => setShowSearch(false)}
        />
    </div>
  );
};

export default Header;
